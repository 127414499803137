import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { StaticPageTemplate } from './static-page-tpl';

const ContactTermCondPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout showContact={false} showNewsletter={false} showFooter={false} meta={frontmatter.meta}>
      <StaticPageTemplate {...frontmatter} />
    </Layout>
  );
};

ContactTermCondPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactTermCondPage;

export const contactTermCondPageQuery = graphql`
  query ContactTermCondPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-terms-and-conditions-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }
        title
        content
      }
    }
  }
`;
