import React from 'react';

import Markdown from '../components/Markdown';

export const StaticPageTemplate = data => (
  <div className="Static">
    <h1 className="Static__title">{data.title}</h1>

    <Markdown content={data.content} />
  </div>
);
